import { type SVGAttributes } from 'react';
import { EmblemSvg } from '../emblem-svg';

export const CrudeOil = (props: SVGAttributes<SVGElement>) => {
  return (
    <EmblemSvg {...props} viewBox="0 0 128 128">
      <path
        d="M102.72 78.5c0-1.45-.45-2.86-1.28-4.21V53.7c.83-1.34 1.28-2.75 1.28-4.21s-.45-2.86-1.28-4.21V20.52H26.55v24.77c-.83 1.34-1.28 2.75-1.28 4.21s.45 2.86 1.28 4.21V74.3c-.83 1.34-1.28 2.75-1.28 4.21s.45 2.86 1.28 4.21v20.59c-.83 1.34-1.28 2.75-1.28 4.21C25.28 116.62 42.61 124 64 124c21.39 0 38.72-7.38 38.72-16.49c0-1.45-.45-2.86-1.28-4.21V82.71c.84-1.34 1.28-2.75 1.28-4.21z"
        fill="#2686c6"
      ></path>

      <g fill="#2167a1">
        <path d="M90.34 58.37c5.29-2.25 8.7-5.12 9.4-7.84c.16-.63.75-1.04 1.38-1.04c.77 0 1.38.62 1.45 1.35c.09-.45.14-.9.14-1.35c0-1.45-.45-2.86-1.28-4.21V20.52H85.01l-24.37 16.4l11.54 2.8c2.75.67 4.7 3.21 4.7 6.14v11.21c0 2.62 2.33 4.6 4.8 4.04c3.16-.72 6.08-1.64 8.66-2.74z"></path>

        <path d="M101.45 74.3V53.7c-3.14 5.08-11.82 9.2-23.12 11.12c-.02 0-.05.01-.07.01c-4.41.74-9.22 1.15-14.25 1.15c-13.73 0-25.79-3.05-32.66-7.64c12.85 14.2 38.33 11.84 38.33 11.84c.93-.06 1.84-.13 2.74-.22c2.4-.23 4.47 1.72 4.47 4.21v13.55c0 1.73 1.52 3.05 3.16 2.73c3.78-.76 7.27-1.79 10.3-3.08c5.29-2.25 8.7-5.12 9.4-7.84c.16-.63.75-1.04 1.38-1.04c.77 0 1.38.62 1.45 1.35c.09-.45.14-.9.14-1.35c0-1.44-.44-2.85-1.27-4.19z"></path>

        <path d="M101.45 103.3V82.71c-3.14 5.08-11.82 9.2-23.12 11.12c-.02 0-.05.01-.07.01c-4.41.74-9.22 1.15-14.25 1.15c-15.49 0-28.85-3.87-35.04-9.47c11.73 17.58 40.71 14.9 40.71 14.9c.96-.06 1.9-.14 2.83-.23c2.36-.23 4.39 1.68 4.39 4.13v8.81c0 3.7 3.38 6.39 6.86 5.48c2.37-.63 4.59-1.37 6.6-2.23c5.29-2.25 8.7-5.12 9.4-7.84c.16-.63.75-1.04 1.38-1.04c.31 0 .87.52 1.48 1.24c.07-.41.11-.83.11-1.24c-.01-1.44-.45-2.85-1.28-4.2z"></path>
      </g>

      <ellipse
        cx="64"
        cy="20.49"
        rx="38.72"
        ry="16.49"
        fill="#b0bec5"
      ></ellipse>

      <g fill="#56bfe6">
        <path d="M37.73 58.37c-5.29-2.25-8.7-5.12-9.4-7.84c-.16-.63-.75-1.04-1.38-1.04c-.95 0-1.67.93-1.42 1.88c2.01 7.54 15.96 13.53 33.59 14.47c.83.04 1.53-.65 1.53-1.51c0-.8-.61-1.45-1.39-1.5c-8.17-.42-15.68-1.97-21.53-4.46z"></path>

        <path d="M37.73 87.38c-5.29-2.25-8.7-5.12-9.4-7.84c-.16-.63-.75-1.04-1.38-1.04c-.95 0-1.67.93-1.42 1.88c2.01 7.54 15.96 13.53 33.59 14.47c.83.04 1.53-.65 1.53-1.51c0-.8-.61-1.45-1.39-1.5c-8.17-.42-15.68-1.97-21.53-4.46z"></path>

        <path d="M37.73 116.39c-5.29-2.25-8.7-5.12-9.4-7.84c-.16-.63-.75-1.04-1.38-1.04c-.95 0-1.67.93-1.42 1.88c2.01 7.54 15.96 13.53 33.59 14.47c.83.04 1.53-.65 1.53-1.51c0-.8-.61-1.45-1.39-1.5c-8.17-.42-15.68-1.97-21.53-4.46z"></path>
      </g>

      <path
        d="M29.42 23.73c.05-4.85 4.52-7.14 8.92-9.06c6.81-2.97 15.92-4.6 25.65-4.6s18.85 1.63 25.65 4.6c4.4 1.92 9.02 4.07 8.93 9.06l2.86-2.44C99.35 11.19 83.13 5.35 64 5.35s-35.93 5.69-37.45 15.94l2.87 2.44z"
        fill="#2167a1"
      ></path>

      <path
        d="M64 7c9.95 0 19.26 1.63 26.21 4.59c5.96 2.54 9.52 5.86 9.52 8.9c0 3.03-3.56 6.36-9.52 8.9c-6.95 2.96-16.26 4.59-26.21 4.59s-19.26-1.63-26.21-4.59c-5.96-2.54-9.52-5.86-9.52-8.9c0-3.03 3.56-6.36 9.52-8.9C44.74 8.63 54.05 7 64 7m0-3c-21.39 0-38.72 7.38-38.72 16.49S42.62 36.98 64 36.98s38.72-7.38 38.72-16.49S85.39 4 64 4z"
        fill="#b9e4ea"
      ></path>

      <path
        d="M77.79 26.89v-2.47l11.59-.13v2.6c0 1.36-2.59 2.47-5.79 2.47s-5.8-1.11-5.8-2.47z"
        fill="#2167a1"
      ></path>

      <path
        d="M43.06 16.98v-1.74c.56-.14 6.88.16 7.57.16v1.59c0 .89-1.69 1.61-3.78 1.61c-2.1-.01-3.79-.73-3.79-1.62z"
        fill="#2167a1"
      ></path>

      <ellipse
        cx="83.59"
        cy="24.64"
        rx="5.79"
        ry="2.47"
        fill="#e0e0e0"
      ></ellipse>

      <ellipse
        cx="46.84"
        cy="15.39"
        rx="3.78"
        ry="1.61"
        fill="#e0e0e0"
      ></ellipse>

      <path
        d="M35.22 29.81c3.99 2.06 8.92 3.47 13.08 4.18"
        fill="none"
        stroke="#ffffff"
        stroke-width="3"
        stroke-linecap="round"
        stroke-miterlimit="10"
      ></path>
    </EmblemSvg>
  );
};
