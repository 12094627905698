import { type SVGAttributes } from 'react';
import { EmblemSvg } from '../emblem-svg';

export const GoldYuan = (props: SVGAttributes<SVGElement>) => {
  return (
    <EmblemSvg
      {...props}
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      image-rendering="optimizeQuality"
      fill-rule="evenodd"
      clip-rule="evenodd"
      viewBox="0 0 512 511.995"
    >
      <path
        fill="#ECCA43"
        fill-rule="nonzero"
        d="M256 0c70.685 0 134.689 28.659 181.015 74.984C483.341 121.31 512 185.315 512 256c0 70.684-28.659 134.689-74.985 181.015-46.326 46.322-110.33 74.98-181.015 74.98-70.685 0-134.689-28.658-181.015-74.98C28.659 390.689 0 326.684 0 256c0-70.685 28.659-134.69 74.985-181.016C121.307 28.659 185.311 0 256 0z"
      />
      <ellipse fill="#F7E259" cx="256" cy="255.998" rx="250.992" ry="250.991" />
      <path
        fill="#F8D548"
        d="M503.753 215.692A252.691 252.691 0 01506.989 256c0 138.614-112.371 250.988-250.989 250.988S5.007 394.614 5.007 256c0-21.857 2.801-43.056 8.051-63.271l246.435 183.476 244.26-160.513z"
      />
      <path
        fill="#D7925B"
        fill-rule="nonzero"
        d="M256 58.922c54.414 0 103.688 22.061 139.353 57.725 35.664 35.661 57.725 84.935 57.725 139.349 0 54.417-22.061 103.692-57.725 139.352-35.665 35.664-84.939 57.726-139.353 57.726-54.414 0-103.688-22.062-139.349-57.726-35.664-35.664-57.725-84.938-57.725-139.352s22.061-103.688 57.725-139.349C152.312 80.983 201.586 58.922 256 58.922z"
      />
      <circle fill="#EDA140" cx="256.001" cy="255.997" r="192.069" />
      <path
        fill="#C26A34"
        fill-rule="nonzero"
        d="M174.165 168.199h56.561l28.533 61.548 27.857-61.548h56.729l-43.244 78.372-15.345 27.392v14.965h52.249v27.645h-52.249v33.226h-51.487v-33.226H180.76v-27.645h53.009v-14.965l-15.091-27.392z"
      />
      <path
        fill="#F3DC6B"
        fill-rule="nonzero"
        d="M168.159 162.192h56.56l28.534 61.548 27.857-61.548h56.729l-43.244 78.373-15.345 27.392v14.964h52.248v27.646H279.25v33.226h-51.487v-33.226h-53.01v-27.646h53.01v-14.964l-15.091-27.392z"
      />
    </EmblemSvg>
  );
};
